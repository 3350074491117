import CollectionItem from "./CollectionItem";

export default function CollectionsPreview() {
    return (
        <div
            id="scrollableDiv"
            className="w-full h-full flex flex-col  items-center justify-center"
        >
            <div className="flex items-center justify-center flex-wrap">
                <CollectionItem
                    size="small"
                    collectionName="Flat Circles"
                    collectionSlug="flat-circles"
                    new={true}
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/408d869614958a5b65b1/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/433b920155f6e88b7991/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4f0ba1ea32191324d060/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/458fb1adf7f53d909e81/xxs",
                        },
                    ]}
                />
                <CollectionItem
                    size="small"
                    collectionName="Olden Vibes"
                    collectionSlug="olden-vibes"
                    new={true}
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/46ea875703f94a097712/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4694a77a93fa598e4015/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/42ec86961eb1327fc7f3/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/44eaa5cf85dba5da1587/xxs",
                        },
                    ]}
                />
                <CollectionItem
                    size="small"
                    collectionName="Soft Blobs"
                    collectionSlug="soft-blobs"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4ed8b6dcaf5f53e0d311/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4d3cb281a2df8851ebe6/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4077887cf5f48be07a2d/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/40f888a59f8fbcbab5cb/xxs",
                        },
                    ]}
                />
            </div>
            <div className="flex items-center justify-center flex-wrap">
                <CollectionItem
                    size="small"
                    collectionName="Raw Forms"
                    collectionSlug="raw-forms"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4f68b15c5859b6cdaadb/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4e729450ba096b08fee7/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4168907bda03109b89ae/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4d568a7401fa82d97aef/xxs",
                        },
                    ]}
                />
                <CollectionItem
                    size="small"
                    collectionName="Playful Strokes"
                    collectionSlug="playful-strokes"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4f5a9e26c0309ae4f215/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/48b281b7efbe609bd5f0/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4a55a930da3504798492/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/451a9a83436d210f43e0/xxs",
                        },
                    ]}
                />
                <CollectionItem
                    size="small"
                    collectionName="Warm Endeavors"
                    collectionSlug="warm-endeavors"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/400fa889832b5634005f/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/438fb0e059a12d2676b9/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/46bd928f73fe8dc2db39/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4369940169d03da83afd/xxs",
                        },
                    ]}
                />
            </div>
            <div className="flex items-center justify-center flex-wrap">
                <CollectionItem
                    size="small"
                    collectionName="Cyan Essence"
                    collectionSlug="cyan-essence"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4a47ba6b0f32f05b8b34/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/40aa9f4023c4d49794d7/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4463b7ab53b1e322fd9c/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4b16a6a53411cd95be69/xxs",
                        },
                    ]}
                />

                <CollectionItem
                    size="small"
                    collectionName="Stochastic Splash"
                    collectionSlug="stochastic-splash"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/430a98f0e8a0d8a19f9a/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4c3fa5d1eecfcafca9bc/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4d0daab197533da468c8/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/4d8885cafdaf1ef5b487/xxs",
                        },
                    ]}
                />
                <CollectionItem
                    size="small"
                    collectionName="Geometric Grace"
                    collectionSlug="geometric-grace"
                    images={[
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/Vw2tcnJWrvMZuLDVz-1D9wiap9kKs0BQ/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/z73tACeYUbPTMN6WzEgNJzGCgz6d_smb/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/ieT9RYdQBs2494HDkL6NvHjR7AuwH1-Y/xxs",
                        },
                        {
                            url: "https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/8CdDitBbxPW1gAE34R-m7E7Iz-_GPa95/xxs",
                        },
                    ]}
                />
            </div>
        </div>
    );
}
