import _ from "lodash";
import { useEffect, useState, useContext, useRef } from "react";

import { useRouter } from "next/router";
import UserContext from "./UserContext";
import Header from "./Header";
import Footer from "./Footer";
import Link from "next/link";
import {
    ChevronDownIcon,
    ChevronRightIcon,
    Squares2X2Icon,
} from "@heroicons/react/20/solid";

function SidebarItem(props) {
    return (
        <div
            className={
                "flex items-center justify-between p-2 px-3 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg text-sm active:bg-gray-100 " +
                (props.selected
                    ? "bg-gray-100 font-medium text-secondary dark:bg-gray-800 dark:text-secondary-light"
                    : "")
            }
        >
            <div>{props.children}</div>
        </div>
    );
}

function SidebarTitleItem(props) {
    let Icon = () => {
        if (props.name === "Browse") {
            return <Squares2X2Icon className="w-4" />;
        } else if (props.selected || props.section === "browse") {
            return <ChevronDownIcon className="w-5 -ml-0.5 -mr-0.5" />;
        } else if (props.selected === false) {
            return <ChevronRightIcon className="w-5 -ml-0.5 -mr-0.5" />;
        }
    };
    return (
        <div
            className={
                "flex text-base font-bold mb-4 mt-5 lg:px-12 xl:px-16 px-8 items-center " +
                (props.selected && !props.subsection
                    ? "text-secondary"
                    : "hover:text-slate-600 dark:hover:text-slate-400")
            }
        >
            <div className="mr-2">
                <Icon />
            </div>
            <div>
                <Link href={props.link}>
                    <a>{props.name}</a>
                </Link>
            </div>
        </div>
    );
}

export function NewLabel() {
    return (
        <span className="text-xs ml-2 p-1 px-2 rounded-lg bg-gray-100 dark:bg-black hover:no-underline no-underline font-medium text-blue-500 dark:text-blue-400">
            New
        </span>
    );
}

function Sidebar(props) {
    const divRef = useRef();

    const scrollToElement = () => {
        divRef.current?.scrollIntoView({
            behavior: "instant",
            block: "nearest",
            inline: "start",
        });
    };
    useEffect(scrollToElement, []);
    return (
        <div className="sticky top-0 bg-white dark:bg-gray-900 h-[calc(100vh-100px)] dark:text-gray-100">
            <div className="pt-8 flex-col h-full">
                <div
                    className={
                        (["collections", "browse"].includes(props.section)
                            ? "h-[75%] pb-16"
                            : "") + " flex-0"
                    }
                >
                    <SidebarTitleItem
                        selected={props.section === "collections"}
                        section={props.section}
                        subsection={props.subsection}
                        name="Illustration Sets"
                        link="/collection"
                    />
                    {props.collections && (
                        <div
                            className={
                                "text-gray-700 dark:text-gray-300 h-full overflow-y-scroll px-8 xl:px-16 faded"
                            }
                        >
                            {Object.entries(props.collections).map(
                                ([slug, name], i) => (
                                    <div
                                        className="last:pb-4"
                                        key={"/collection/" + slug}
                                    >
                                        <Link href={`/collection/${slug}`}>
                                            <a>
                                                <div
                                                    ref={
                                                        props.subsection ===
                                                        name
                                                            ? divRef
                                                            : null
                                                    }
                                                >
                                                    <SidebarItem
                                                        selected={
                                                            props.subsection ===
                                                            name
                                                        }
                                                    >
                                                        {name}
                                                        {i < 2 && <NewLabel />}
                                                    </SidebarItem>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
                <div
                    className={
                        (props.section === "categories"
                            ? "h-[75%] pb-16"
                            : "") + " flex-0"
                    }
                >
                    <SidebarTitleItem
                        selected={props.section === "categories"}
                        name="Categories"
                        link="/category"
                    />
                    {props.categories && (
                        <div
                            className={
                                "text-gray-700 dark:text-gray-300 h-full overflow-y-scroll px-8 xl:px-16 faded"
                            }
                        >
                            {props.categories.map(({ slug, name }) => (
                                <div className="last:pb-4" key={"/category/" + (slug || name)}>
                                    <Link href={`/category/${name}`} key={name}>
                                        <a>
                                            <div
                                                ref={
                                                    props.subsection === name
                                                        ? divRef
                                                        : null
                                                }
                                            >
                                                <SidebarItem
                                                    selected={
                                                        props.subsection ===
                                                        name
                                                    }
                                                >
                                                    {_.startCase(name)}
                                                </SidebarItem>
                                            </div>
                                        </a>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="">
                    <SidebarTitleItem
                        name="Browse"
                        selected={props.section === "browse"}
                        subsection={props.subsection}
                        link="/browse"
                    />
                </div>
            </div>
        </div>
    );
}

export default function LibraryLayout(props) {
    const router = useRouter();
    const { user, supabase } = useContext(UserContext);
    const [selectedItem, setSelectedItem] = useState(props.initialItem || null);

    useEffect(() => {
        const imageId = router.asPath.includes("collection")
            ? router.asPath.split("/").slice(-1)[0]
            : router.asPath.slice(1);
        if (
            !props.initialItem &&
            imageId &&
            !imageId.startsWith("#") &&
            imageId !== "[[...slug]]" &&
            !selectedItem?.url &&
            imageId.includes("-")
        ) {
            // setSelectedItem({
            //     image: imageId,
            // });
        }
    }, [router]);

    return (
        <div className="flex-1">
            <div className="top-0 dark:text-white border-b border-gray-200 dark:border-gray-800 md:pb-2  dark:bg-gray-900 dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-950">
                <div className="relative z-10">
                    <Header
                        className="secondary w-full"
                        {...props}
                        supabase={supabase}
                        user={user}
                        title={props.title}
                        description={
                            selectedItem?.description
                                ? `Vector image of ${selectedItem?.description}. ${selectedItem?.caption || ""}`
                                : props.description
                                ? `Free ${
                                      props.subsection || props.title || ""
                                  } SVG vector images generated by AI. ${
                                      props.description || ""
                                  } ${props.haiku || ""}`
                                : null
                        }
                        image={selectedItem?.url}
                    />
                </div>
            </div>
            <div className="flex">
                <div className="sm:block hidden sticky border-r bg-white border-gray-200 lg:w-[360px] dark:bg-gray-900 dark:border-gray-700">
                    <Sidebar {...props} />
                </div>
                <div className="flex-1 bg-gray-100 dark:bg-gray-950 dark:text-gray-100 h-full">
                    {props.children}
                </div>
            </div>
            <div>
                <Footer {...props} user={user} />
            </div>
        </div>
    );
}
