/* eslint-disable @next/next/no-img-element */
import * as React from "react";
import { throttle, isEmpty } from "lodash";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import { useEffect, useState } from "react";
import { Spinner } from "./Spinner";
import { MasonryItem } from "./MasonryItem";

export default function MasonryImageList(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [inError, setInError] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isEmptyState, setIsEmpty] = useState(false);

    useEffect(() => {
        props.itemsProvider.setOnMoreNeeded(() => fetchMore());
        // return () => {
        //     return props.itemsProvider.onMoreNeeded = null;
        // }
    }, [props.itemsProvider])

    const fetchMore = async () => {
        console.log("fetch more", props.query)
        if (isLoading) {
            return;
        }
        const items = props.itemsProvider.getItems() || [];
        if (props.limit && items.length >= props.limit) {
            return;
        }
        setIsLoading(true);
        setIsEmpty(false);
        try {
            const result = await axios.get(
                `/api/list?from=${items.length}&q=${
                    props.query
                }&base=${props.baseUrl}`
            );
            let newItems = result?.data?.results || [];

            const empty = !newItems.length && !items.length;
            setIsEmpty(empty);

            const prevItems = items || [];
            if (
                prevItems &&
                prevItems[prevItems.length - 1]?.image ==
                    newItems[newItems.length - 1]?.image
            ) {
                return prevItems;
            }
            let updatedItems = [...prevItems, ...newItems];
            if (props.limit) {
                updatedItems = result.slice(0, props.limit);
            }
            props.itemsProvider?.setItems(updatedItems);

            if (!newItems.length) {
                setHasMore(false);
            }
        } catch (err) {
            console.error(err)
            setInError(true);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    };
    const fetchMoreThrottled = React.useMemo(() => throttle(fetchMore, 1000));
    useEffect(() => {
        // console.log("query changed", props.query, props.initialQuery, props.initialImages, props.limit, hasMore);
        if (props.query === props.initialQuery) {
            // console.log("query is the same", props.query, props.initialQuery)
            if (!props.canLoadMore) {
                setHasMore(false);
            }
            return;
        }
        setInError(false);
        setHasMore(
            !props.limit &&
                (isEmpty(props.initialImages) ||
                    (props.query && props.query !== props.initialQuery) ||
                    props.canLoadMore !== false)
        );
        console.log("setting has more",           !props.limit &&
        (isEmpty(props.initialImages) ||
            (props.query && props.query !== props.initialQuery) ||
            props.canLoadMore !== false));
        setIsLoading(false);
        props.itemsProvider?.setItems([]);
        setIsEmpty(false);
    }, [props.query]);

    if (isEmptyState) {
        return (
            <div className="flex flex-col my-12 items-center justify-center h-full">
                <div className="text-2xl font-bold text-gray-500">
                    No results found
                </div>
            </div>
        );
    }

    return (
        <InfiniteScroll
            loadMore={fetchMoreThrottled}
            hasMore={hasMore}
            initialLoad={
                Boolean(props.limit ||
                isEmpty(props.initialImages) ||
                props.query !== props.initialQuery)
            }
            threshold={20}
            loader={<Spinner className="my-8" key="spinner" />}
        >
            <div
                id="scrollableDiv"
                className={(props.align === "left" ? 'items-center justify-center sm:items-start sm:justify-start' : 'items-center justify-center') + " w-full h-full flex flex-wrap group"}
            >
                {props.itemsProvider.getItems().map((item, i) => (
                    <MasonryItem
                        small={props.align === "left"}
                        size={props.itemSize}
                        key={props.query + i}
                        baseUrl={props.baseUrl}
                        className={props.itemClassName}
                        {...item}
                        onItemClick={() => props.onItemClick?.(item, i)}
                    />
                ))}
            </div>
        </InfiniteScroll>
    );
}
